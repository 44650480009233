<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<AccountNav></AccountNav>
			</div>
			<div class="admin-col whole_000 whole_fff">
				<div class="acc_box api_box accu_box">
					<div class="container">
						<div class="acc-hd flex flex-ac flex-zBetween">
							<div class="acc-tit">{{$t('lctit')}}</div>
						</div>
						<div class="acc-in flex flex-ac flex-zEnd">
							<div class="acc-in-r acc-btn flex flex-ac">
								<el-button  type="primary" class="btn btn-mb0" @click="transfer">{{$t('lcbtn')}}
								</el-button>
							</div>
						</div>
						<div class="acc-table exte-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="coinSymbol" :label="$t('lctit')">
									<template slot-scope="scope">
										<span>{{scope.row.coinSymbol}} {{$t('meun10_5')}} </span>
									</template>
								</el-table-column>
								<el-table-column prop="num" :label="$t('mTxt12')">
								</el-table-column>
								<el-table-column prop="earnNum" :label="$t('lcth1')">
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		lockedAssets
	} from '@/api/api/earn'
	import AccountNav from '@/components/AccountNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			AccountNav,
			Foot
		},
		data() {
			return {
				tableData: []
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				lockedAssets().then(res => {
					if (res.code == 0) {
						this.tableData = res.data
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			transfer() {
				this.$router.push('/earn')
			}
		}
	}
</script>
